<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="primary mx-sm-2 mx-1" dark icon>
        <v-icon>mdi-translate</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group v-model="currentLocale">
        <v-list-item value="en" @click="changeLocale('en')">
          {{ $vuetify.lang.t('$vuetify.languageSelectorOptions.english') }}
        </v-list-item>
        <v-list-item value="ar" @click="changeLocale('ar')">
          {{ $vuetify.lang.t('$vuetify.languageSelectorOptions.arabic') }}
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { changeLocale } from '@/modules/app/helpers/utils'

export default {
  name: 'KurccLocaleSelector',
  data () {
    return {
      currentLocale: 'ar',
      changeLocale
    }
  },
  computed: {
    savedLocale () {
      return localStorage.getItem('locale')
    }
  },
  methods: {
    getLocale () {
      if (this.savedLocale) {
        return this.savedLocale
      } else {
        return this.$vuetify.lang.current
      }
    }
  },
  created () {
    this.currentLocale = this.getLocale()
    this.changeLocale(this.currentLocale)
  }
}
</script>
